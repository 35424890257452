<template>
    <div class="task-data">
        <el-scrollbar style="height: 100%;">
            <div class="container">
                <div class="filters">
                    <div class="select">
                        <span class="label">所属班级</span>
                        <el-select v-model="classId" @change="taskId = 1; setSelectChange()" disabled>
                            <el-option v-for="item in classSelect" :key="item.id" :value="item.id" :label="item.name"></el-option>
                        </el-select>
                    </div>
                    <div class="select">
                        <span class="label">实训名称</span>
                        <el-select v-model="taskId" @change="setSelectChange">
                            <el-option v-for="item in taskSelect" :key="item.id" :value="item.id" :label="item.name"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="ECharts">
                    <div class="average">
                        <my-ECharts v-if="showECharts" id="score" :options="scoreOption" ref="ECharts"></my-ECharts>
                    </div>
                    <div class="level">
                        <div class="ratio-item" v-for="item in levelRatio" :key="item.label">
                            <el-progress type="circle" :percentage="item.value" :color="item.color" :width="180" :stroke-width="12"></el-progress>
                            <span class="ratio">{{ item.label }}率</span>
                            <span class="count">{{ item.label }}人数<span :style="{ color: item.color }"> {{ item.count }} </span>人</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="ranking">
                    <div class="title">{{ classSelect[classId - 1].name }}</div>
                    <div class="desc">作业成绩排名</div>
                    <el-table
                        v-if="flag"
                        :data="tableData"
                        style="width: 100%"
                    >
                        <el-table-column prop="rank" label="排名" width="180" align="center"></el-table-column>
                        <el-table-column prop="name" label="学生名称" align="center"></el-table-column>
                        <el-table-column prop="type" label="作业类型" align="center"></el-table-column>
                        <el-table-column prop="score" label="分数" sortable align="center"></el-table-column>
                    </el-table>
                </div> -->
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
import MyECharts from "../../../../components/common/MyECharts.vue"
export default {
    name: 'TaskData',
    components: {
        MyECharts
    },
    props: {},
    data() {
        return {
            flag: true,
            tableData: [],
            classSelect: [{
                id: 1,
                name: "三年一班"
            }],
            classId: 1,
            taskSelect: [{
                id: 1,
                name: "课后视频作业"
            }, {
                id: 2,
                name: "课后图文作业"
            }],
            taskId: 1,
            scoreMap: {
                "11": [100, 98, 85, 80, 75, 66, 60, 60, 59.5, 21],
                "12": [99, 87, 78, 65, 59, 56, 40, 38, 35, 10],
                "21": [100, 98, 95, 89, 87, 86, 78, 75, 66, 60],
                "22": [79, 64, 60, 60, 56, 35, 22, 15, 10]
            },
            showECharts: false,
            levelRatio: [{
                label: "优秀",
                value: 0,
                count: 0,
                color: "#2821fc"
            }, {
                label: "良好",
                value: 0,
                count: 0,
                color: "#fccc21"
            }, {
                label: "及格",
                value: 0,
                count: 0,
                color: "#f821fc"
            }],
            scoreOption: {
                tooltip: {
                    show: true
                },
                xAxis: {
                    type: 'category',
                    data: ['平均分', '最高分', '最低分']
                },
                yAxis: {
                    type: 'value'
                },
                series: [{
                    barWidth: 15,
                    data: [{
                        value: 0,
                        itemStyle: {
                            color: '#4ddcab'
                        }
                    }, {
                        value: 0,
                        itemStyle: {
                            color: '#2821fc'
                        }
                    }, {
                        value: 0,
                        itemStyle: {
                            color: '#bf25fa'
                        }
                    }],
                    type: 'bar'
                }]
            }
        }
    },
    computed: {},
    methods: {
        init() {},
        EChartsResize() {
            this.$refs.ECharts && this.$refs.ECharts.resize();
        },
        setSelectChange() {
            const targetScore = this.scoreMap[`${this.classId}${this.taskId}`];
            if(!targetScore) return;
            let max = targetScore[0], min = targetScore[0], sum = 0, average = 0, excellent = 0, good = 0, pass = 0;
            let i = targetScore.length;
            while(i--) {
                const item = targetScore[i];
                if(item > max) max = item;
                if(min > item) min = item;
                if(item >= 90) {
                    excellent++;
                }else if(item >= 80) {
                    good++;
                }
                if(item >= 60) {
                    pass++;
                };
                sum += item;
            }
            average = (sum / targetScore.length).toFixed(2);
            this.levelRatio[0].count = excellent;
            this.levelRatio[1].count = good;
            this.levelRatio[2].count = pass;
            this.levelRatio[0].value = Math.floor(excellent / targetScore.length * 100);
            this.levelRatio[1].value = Math.floor(good / targetScore.length * 100);
            this.levelRatio[2].value = Math.floor(pass / targetScore.length * 100);
            this.scoreOption.series[0].data[0].value = average;
            this.scoreOption.series[0].data[1].value = max;
            this.scoreOption.series[0].data[2].value = min;
            this.showECharts = false;
            this.$nextTick(() => this.showECharts = true);
            this.setTableData();
        },
        setTableData() {
            const targetScore = this.scoreMap[`${this.classId}${this.taskId}`];
            if(!targetScore) return;
            const type = this.taskSelect[this.taskId - 1].name.includes("图文") ? "图文" : "视频";
            const newVal = targetScore.map((item, index) => ({
                name: `学生${index + 1}`,
                score: item,
                type,
            })).map((item, index) => ({ ...item, rank: index + 1 }));
            this.tableData.splice(0, this.tableData.length, ...newVal);
            this.flag = false;
            this.$nextTick(() => this.flag = true);
        }
    },
    created() {
        this.setSelectChange();
    },
    mounted() {
        window.addEventListener("resize", this.EChartsResize);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.EChartsResize);
    },
}
</script>

<style scoped lang="scss">
.task-data {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    padding: 30px 0;
    font-size: 14px;
    color: #333;
    .container {
        flex: 1;
        box-sizing: border-box;
        padding: 0 30px;
    }
    .filters {
        display: flex;
        padding-bottom: 20px;
        border-bottom: 1px solid #DADCE7;   
        .select {
            display: flex;
            align-items: center;
            margin-right: 20px;
            .label {
                margin-right: 10px;
            }
        }
    }
    .ECharts {
        box-sizing: border-box;
        display: flex;
        width: 100%;
        height: 339px;
        margin: 30px 0 40px;
        .average,
        .level {
            border: 1px solid #DADCE7;
            border-radius: 6px;
        }
        .average {
            flex: 55.3;
            margin-right: 30px;
        }
        .level {
            flex: 100.4;
            display: flex;
            align-items: center;
            justify-content: space-around;
            .ratio-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 16px;
                color: #000;
                .ratio {
                    margin: 26px 0 20px;
                }
                .count {
                    font-size: 14px;
                    color: #666;
                }
            }
        }
    }
    .ranking {
        .title {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 30px;
        }
        .desc {
            margin-left: 30px;
        }
    }
}
/deep/ .el-scrollbar__wrap {
    overflow-x: hidden;
}
</style>
